import { listings_redux_status_wise_keys } from "features/Listings/Listings.constants";
import {
  EXLY_SCHEDULE__BLOCK_NEW_SLOTS_SESSION,
  EXLY_SCHEDULE__RESCHEDULE_SESSION,
  EXLY_SCHEDULE__CHANGE_PROCESSING,
  EXLY_SCHEDULE__SET_ALL_OFFERINGS,
  EXLY_SCHEDULE__RESET_ALL_OFFERINGS,
  EXLY_SCHEDULELIST__SET_LIVE_OFFERINGS,
  EXLY_SCHEDULELIST__RESET_LIVE_OFFERINGS,
  EXLY_SCHEDULELIST__SHOW_LIVE_OFFERINGS_LOADING,
  EXLY_SCHEDULELIST__SET_DRAFT_OFFERINGS,
  EXLY_SCHEDULELIST__RESET_DRAFT_OFFERINGS,
  EXLY_SCHEDULELIST__SHOW_DRAFT_OFFERINGS_LOADING,
  EXLY_SCHEDULELIST__SET_SOLDOUT_OFFERINGS,
  EXLY_SCHEDULELIST__RESET_SOLDOUT_OFFERINGS,
  EXLY_SCHEDULELIST__SHOW_SOLDOUT_LOADING,
  EXLY_SCHEDULELIST__SET_PAST_OFFERINGS,
  EXLY_SCHEDULELIST__RESET_PAST_OFFERINGS,
  EXLY_SCHEDULELIST__SHOW_PAST_OFFERINGS_LOADING,
  EXLY_SCHEDULELIST__SET_PLAN_OFFERINGS,
  EXLY_SCHEDULELIST__SET_MEMBERS,
  EXLY_SCHEDULELIST__UPDATE_OFFERING,
  EXLY_SCHEDULELIST_SET_ACTIVE_PLANS_LIST,
  EXLY_SCHEDULELIST__SET_SHOW_DELETE_MODAL,
  EXLY_SCHEDULELIST__REQUEST_DELETE_OFFERING,
  EXLY_SCHEDULELIST__SET_SHOW_DUPLICATE_OFFERING_MODAL_LOADER,
  EXLY_SCHEDULELIST__SHOW_DUPLICATE_OFFERING_MODAL,
  EXLY_SCHEDULELIST__CREATOR_DUPLICATE_OFFERING_DATA,
} from "./actions";

const listingProperties = {
  isLoading: false,
  data: [],
  error: {},
  total_pages: 0,
  pages_fetched: 0,
  isFiltering: false,
  filteringType: 0,
  noData: false,
  total_items: 0,
  filtered_total_items: 0,
};

const initialState = {
  reschedule_session: {},
  block_new_slots_session: {},
  processing: false,
  allOfferings: { ...listingProperties },
  live: { ...listingProperties },
  draft: { ...listingProperties },
  past: { ...listingProperties },
  soldout: { ...listingProperties },
  plan: {
    isLoading: false,
    data: [],
    activePlans: [],
    error: {},
    noData: false,
  },
  members: {
    data: [],
    error: {},
  },
  showOfferingDuplicateModal: false,
  offeringDuplication: {
    duplicateOfferingUuid: "", // parent offering uuid, use for fetch avaiable entities list.
    processing: false,
  },
  delete_modal: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXLY_SCHEDULELIST__SET_SHOW_DELETE_MODAL: {
      return { ...state, delete_modal: payload };
    }
    case EXLY_SCHEDULE__SET_ALL_OFFERINGS: {
      return { ...state, allOfferings: payload, processing: false };
    }
    case EXLY_SCHEDULE__RESET_ALL_OFFERINGS: {
      return {
        ...state,
        allOfferings: {
          ...listingProperties,
          data: [],
        },
      };
    }
    case EXLY_SCHEDULE__CHANGE_PROCESSING: {
      return { ...state, processing: payload };
    }
    case EXLY_SCHEDULE__RESCHEDULE_SESSION: {
      return { ...state, reschedule_session: payload };
    }
    case EXLY_SCHEDULE__BLOCK_NEW_SLOTS_SESSION: {
      return { ...state, block_new_slots_session: payload };
    }
    case EXLY_SCHEDULELIST__SET_LIVE_OFFERINGS: {
      return {
        ...state,
        live: {
          isLoading: payload.isLoading,
          data: [...payload.data],
          error: payload.error,
          total_pages: payload.total_pages,
          pages_fetched: payload.pages_fetched,
          isFiltering: payload.isFiltering,
          filteringType: payload.filteringType,
          noData: payload.noData,
          total_items: payload.total_items,
          filtered_total_items: payload.total_type,
        },
      };
    }
    case EXLY_SCHEDULELIST__SHOW_LIVE_OFFERINGS_LOADING: {
      return {
        ...state,
        live: {
          ...state.live,
          isLoading: true,
        },
      };
    }
    case EXLY_SCHEDULELIST__RESET_LIVE_OFFERINGS: {
      return {
        ...state,
        live: {
          ...listingProperties,
          data: [],
        },
      };
    }
    case EXLY_SCHEDULELIST__SET_DRAFT_OFFERINGS: {
      return {
        ...state,
        draft: {
          isLoading: payload.isLoading,
          data: [...payload.data],
          error: payload.error,
          total_pages: payload.total_pages,
          pages_fetched: payload.pages_fetched,
          isFiltering: payload.isFiltering,
          filteringType: payload.filteringType,
          noData: payload.noData,
        },
      };
    }
    case EXLY_SCHEDULELIST__SHOW_DRAFT_OFFERINGS_LOADING: {
      return {
        ...state,
        draft: {
          ...state.draft,
          isLoading: true,
        },
      };
    }
    case EXLY_SCHEDULELIST__RESET_DRAFT_OFFERINGS: {
      return {
        ...state,
        draft: {
          ...listingProperties,
          data: [],
        },
      };
    }
    case EXLY_SCHEDULELIST__SET_SOLDOUT_OFFERINGS: {
      return {
        ...state,
        soldout: {
          isLoading: payload.isLoading,
          data: [...payload.data],
          error: payload.error,
          total_pages: payload.total_pages,
          pages_fetched: payload.pages_fetched,
          isFiltering: payload.isFiltering,
          filteringType: payload.filteringType,
          noData: payload.noData,
        },
      };
    }
    case EXLY_SCHEDULELIST__SHOW_SOLDOUT_LOADING: {
      return {
        ...state,
        soldout: {
          ...state.soldout,
          isLoading: true,
        },
      };
    }
    case EXLY_SCHEDULELIST__RESET_SOLDOUT_OFFERINGS: {
      return {
        ...state,
        soldout: {
          ...listingProperties,
          data: [],
        },
      };
    }
    case EXLY_SCHEDULELIST__SHOW_PAST_OFFERINGS_LOADING: {
      return {
        ...state,
        past: {
          ...state.past,
          isLoading: true,
        },
      };
    }
    case EXLY_SCHEDULELIST__SET_PAST_OFFERINGS: {
      return {
        ...state,
        past: {
          isLoading: payload.isLoading,
          data: [...payload.data],
          error: payload.error,
          total_pages: payload.total_pages,
          pages_fetched: payload.pages_fetched,
          isFiltering: payload.isFiltering,
          filteringType: payload.filteringType,
          noData: payload.noData,
        },
      };
    }
    case EXLY_SCHEDULELIST__RESET_PAST_OFFERINGS: {
      return {
        ...state,
        past: {
          ...listingProperties,
          data: [],
        },
      };
    }
    case EXLY_SCHEDULELIST__SET_PLAN_OFFERINGS: {
      return {
        ...state,
        plan: {
          isLoading: payload.isLoading,
          data: [...payload.data],
          error: payload.error,
          noData: payload.noData,
        },
      };
    }
    case EXLY_SCHEDULELIST__SET_MEMBERS: {
      return {
        ...state,
        members: {
          data: payload.data,
          error: payload.error,
        },
      };
    }
    case EXLY_SCHEDULELIST__UPDATE_OFFERING: {
      const temp = state[payload.offeringStatus]?.data;

      const updatedTemp = temp.map((item) => {
        if (item.uuid === payload.updatedOffering.uuid) {
          return { ...payload.updatedOffering };
        } else {
          return item;
        }
      });

      return {
        ...state,
        [payload.offeringStatus]: {
          ...state[payload.offeringStatus],
          data: updatedTemp,
        },
      };
    }
    case EXLY_SCHEDULELIST_SET_ACTIVE_PLANS_LIST: {
      return {
        ...state,
        plan: {
          ...state.plan,
          activePlans: payload,
        },
      };
    }
    case EXLY_SCHEDULELIST__CREATOR_DUPLICATE_OFFERING_DATA: {
      return {
        ...state,
        offeringDuplication: payload,
      };
    }
    case EXLY_SCHEDULELIST__SHOW_DUPLICATE_OFFERING_MODAL: {
      return {
        ...state,
        showOfferingDuplicateModal: payload,
      };
    }
    case EXLY_SCHEDULELIST__REQUEST_DELETE_OFFERING: {
      const status = payload?.status;
      const listingUuid = payload?.uuid;
      const reduxType = listings_redux_status_wise_keys[status];
      let offeringList = state?.[reduxType]?.data;
      offeringList = offeringList?.filter(({ uuid }) => uuid != listingUuid);
      return {
        ...state,
        [reduxType]: {
          ...state[reduxType],
          data: offeringList,
        },
      };
    }
    case EXLY_SCHEDULELIST__SET_SHOW_DUPLICATE_OFFERING_MODAL_LOADER: {
      return {
        ...state,
        offeringDuplication: {
          ...state?.offeringDuplication,
          processing: payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
