export const EXLY_SCHEDULE__GET_ALL_OFFERINGS =
  "@EXLY_SCHEDULE/GET_ALL_OFFERINGS";
export const EXLY_SCHEDULE__RESET_ALL_OFFERINGS =
  "@EXLY_SCHEDULE/RESET_ALL_OFFERINGS";
export const EXLY_SCHEDULE__SET_ALL_OFFERINGS =
  "@EXLY_SCHEDULE/SET_ALL_OFFERINGS";
export const EXLY_SCHEDULE__CHANGE_PROCESSING =
  "@EXLY_SCHEDULE/CHANGE_PROCESSING";
export const EXLY_SCHEDULE__RESCHEDULE_SESSION =
  "@EXLY_SCHEDULE/RESCHEDULE_SESSION";
export const EXLY_SCHEDULE__BLOCK_NEW_SLOTS_SESSION =
  "@EXLY_SCHEDULE/BLOCK_NEW_SLOTS";

export const EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS =
  "@EXLY_SCHEDULELIST/REQUEST_LIVE_OFFERINGS";
export const EXLY_SCHEDULELIST__SET_LIVE_OFFERINGS =
  "@EXLY_SCHEDULELIST/SET_LIVE_OFFERINGS";
export const EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS_TYPE =
  "@EXLY_SCHEDULELIST/REQUEST_LIVE_OFFERINGS_TYPE";
export const EXLY_SCHEDULELIST__RESET_LIVE_OFFERINGS =
  "@EXLY_SCHEDULELIST/RESET_LIVE_OFFERINGS";
export const EXLY_SCHEDULELIST__SHOW_LIVE_OFFERINGS_LOADING =
  "@EXLY_SCHEDULELIST/EXLY_SCHEDULELIST__SHOW_LIVE_OFFERINGS_LOADING";
export const EXLY_SCHEDULELIST__REQUEST_DELETE_LIVE_OFFERINGS =
  "@EXLY_SCHEDULELIST/EXLY_SCHEDULELIST__REQUEST_DELETE_LIVE_OFFERINGS";

export const EXLY_SCHEDULELIST__REQUEST_SOLDOUT_OFFERINGS =
  "@EXLY_SCHEDULELIST/REQUEST_SOLDOUT_OFFERINGS";
export const EXLY_SCHEDULELIST__SET_SOLDOUT_OFFERINGS =
  "@EXLY_SCHEDULELIST/SET_SOLDOUT_OFFERINGS";
export const EXLY_SCHEDULELIST__REQUEST_SOLDOUT_OFFERINGS_TYPE =
  "@EXLY_SCHEDULELIST/REQUEST_SOLDOUT_OFFERINGS_TYPE";
export const EXLY_SCHEDULELIST__RESET_SOLDOUT_OFFERINGS =
  "@EXLY_SCHEDULELIST/RESET_SOLDOUT_OFFERINGS";
export const EXLY_SCHEDULELIST__SHOW_SOLDOUT_LOADING =
  "@EXLY_SCHEDULELIST/EXLY_SCHEDULELIST__SHOW_SOLDOUT_LOADING";
export const EXLY_SCHEDULELIST__REQUEST_DELETE_OFFERING =
  "@EXLY_SCHEDULELIST/EXLY_SCHEDULELIST__REQUEST_DELETE_OFFERINGS";

export const EXLY_SCHEDULELIST__REQUEST_DRAFT_OFFERINGS =
  "@EXLY_SCHEDULELIST/REQUEST_DRAFT_OFFERINGS";
export const EXLY_SCHEDULELIST__SET_DRAFT_OFFERINGS =
  "@EXLY_SCHEDULELIST/SET_DRAFT_OFFERINGS";
export const EXLY_SCHEDULELIST__REQUEST_DRAFT_OFFERINGS_TYPE =
  "@EXLY_SCHEDULELIST/REQUEST_DRAFT_OFFERINGS_TYPE";
export const EXLY_SCHEDULELIST__RESET_DRAFT_OFFERINGS =
  "@EXLY_SCHEDULELIST/RESET_DRAFT_OFFERINGS";
export const EXLY_SCHEDULELIST__SHOW_DRAFT_OFFERINGS_LOADING =
  "@EXLY_SCHEDULELIST/EXLY_SCHEDULELIST__SHOW_DRAFT_OFFERINGS_LOADING";
export const EXLY_SCHEDULELIST__REQUEST_DELETE_DRAFT_OFFERINGS =
  "@EXLY_SCHEDULELIST/EXLY_SCHEDULELIST__REQUEST_DELETE_DRAFT_OFFERINGS";

export const EXLY_SCHEDULELIST__REQUEST_PAST_OFFERINGS =
  "@EXLY_SCHEDULELIST/REQUEST_PAST_OFFERINGS";
export const EXLY_SCHEDULELIST__SET_PAST_OFFERINGS =
  "@EXLY_SCHEDULELIST/SET_PAST_OFFERINGS";
export const EXLY_SCHEDULELIST__REQUEST_PAST_OFFERINGS_TYPE =
  "@EXLY_SCHEDULELIST/REQUEST_PAST_OFFERINGS_TYPE";
export const EXLY_SCHEDULELIST__RESET_PAST_OFFERINGS =
  "@EXLY_SCHEDULELIST/RESET_PAST_OFFERINGS";
export const EXLY_SCHEDULELIST__SHOW_PAST_OFFERINGS_LOADING =
  "@EXLY_SCHEDULELIST/EXLY_SCHEDULELIST__SHOW_PAST_OFFERINGS_LOADING";

export const EXLY_SCHEDULELIST__REQUEST_PLAN_OFFERINGS =
  "@EXLY_SCHEDULELIST/REQUEST_PLAN_OFFERINGS";
export const EXLY_SCHEDULELIST__SET_PLAN_OFFERINGS =
  "@EXLY_SCHEDULELIST/SET_PLAN_OFFERINGS";

export const EXLY_SCHEDULELIST__REQUEST_DELETE_PLANS =
  "@EXLY_SCHEDULELIST/EXLY_SCHEDULELIST__REQUEST_DELETE_PLANS";
export const EXLY_SCHEDULELIST_SET_ACTIVE_PLANS_LIST =
  "@EXLY_SCHEDULELIST_SET_ACTIVE_PLANS_LIST";

export const EXLY_SCHEDULELIST_REQUEST_DUPLICATE_OFFERINGS =
  "@EXLY_SCHEDULELIST/REQUEST_DUPLICATE_OFFERINGS";

export const EXLY_SCHEDULELIST__REQUEST_MEMBERS =
  "@EXLY_SCHEDULELIST/REQUEST_MEMBERS";
export const EXLY_SCHEDULELIST__SET_MEMBERS = "@EXLY_SCHEDULELIST/SET_MEMBERS";
export const EXLY_SCHEDULELIST__UPDATE_OFFERING =
  "@EXLY_SCHEDULELIST/UPDATE_OFFERING";

export const EXLY_SCHEDULELIST__CREATOR_DUPLICATE_OFFERING_DATA =
  "@EXLY_SCHEDULELIST/CREATOR_DUPLICATE_OFFERING_DATA";

export const EXLY_SCHEDULELIST__SHOW_DUPLICATE_OFFERING_MODAL =
  "@EXLY_SCHEDULELIST/SHOW_DUPLICATE_OFFERING_MODAL";
export const EXLY_SCHEDULELIST__SET_SHOW_DELETE_MODAL =
  "@EXLY_SCHEDULELIST/DELETE_MODAL";
export const EXLY_SCHEDULELIST__SET_SHOW_DUPLICATE_OFFERING_MODAL_LOADER =
  "@EXLY_SCHEDULELIST/DUPLICATE_OFFERING_MODAL_LOADER";
